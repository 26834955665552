body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.appStyle {
  padding-top: 8vh;
}

.inputImage {
  color: #000000;
  border: 2px solid #000000;
  border-radius: 10px;
  padding: 7px 25px;
  background: transparent;
  max-width: 190px;
}

.inputImage:active {
  box-shadow: 2px 2px 15px #000000 inset;
}

.custom-paper {
  height: calc(100% - 9vh);
  overflow: auto;
}